<template>
  <div>
    <app-input v-model="auth.fullname" type="text" name="Ad Soyad" label="Ad Soyad:" placeholder="Ad Soyad" rules="required" />
    <app-input v-model="auth.phone" type="phone" name="Telefon" label="Telefon:" placeholder="Telefon" rules="required|min:13" />
    <app-input v-model="auth.email" type="email" name="E-Posta" label="E-Posta:" placeholder="E-Posta" rules="required|email" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      auth: {},
    };
  },
  watch: {
    auth: {
      handler() {
        this.$emit("input", this.auth);
      },
      deep: true,
    },
    value() {
      this.auth = this.value;
    },
  },
  mounted() {
    this.auth = this.value;
  },
};
</script>

<style></style>
