<template>
  <app-overlay loading-variable="modalOverlay">
    <b-row>
      <b-col v-for="(item, key) in fields" :key="key" cols="6" md="3">
        <div class="d-flex flex-column align-items-center gap-10">
          <b-avatar variant="light-dark" size="50">
            <mdi-icon size="30" :icon="item.icon" />
          </b-avatar>
          <small>{{ item.label }}</small>
          <h5>
            {{ physician[item.key] }} <span v-if="item.addition"> {{ item.addition }}</span>
          </h5>
        </div>
      </b-col>
    </b-row>
  </app-overlay>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        { key: "assignedBranches", label: "Atanan Sicil Sayısı", icon: "MapMarkerCheckOutline" },
        {
          key: "assignmentTime",
          label: "Atama Süresi",
          icon: "ClockTimeEightOutline",
          addition: " Dk.",
        },
        {
          key: "assignedTime",
          label: "Atanan Süre",
          icon: "History",
          addition: " Dk.",
        },
        {
          key: "totalTimeLeft",
          label: "Toplam Kalan Süre",
          icon: "ClockMinusOutline",
          addition: " Dk.",
        },
      ],
    };
  },
  computed: {
    physician() {
      return this.$store.getters?.appModalItem?.data?.physician;
    },
  },
};
</script>
