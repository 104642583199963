<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="employeeform">
      <auth-form v-model="auth" />
      <app-select-input
        v-model="employeeData.branch"
        name="Şube"
        select_label="name"
        label="Şube:"
        placeholder="Şube Seçiniz..."
        :options="riskomerBranches"
        :reduce="(option) => option._id"
        rules="required"
      />
      <div v-if="updateItem">
        <app-select-input
          v-if="updateItem && updateItem.auth && updateItem.auth.usingPanel && updateItem.auth.allowed"
          v-model="auth.role"
          name="Görevi"
          label="Görevi:"
          placeholder="Görev Seçiniz..."
          :options="roles"
          select_label="value"
          rules="required"
        />
        <app-input v-else v-model="employeeData.duty" name="Görevi" label="Görevi:" placeholder="Görev Seçiniz..." rules="required" />
      </div>
      <div v-else>
        <app-select-input
          v-if="usingPanel"
          v-model="auth.role"
          name="Görevi"
          label="Görevi:"
          placeholder="Görev Seçiniz..."
          :options="roles"
          select_label="value"
          rules="required"
        />
        <app-input v-else v-model="employeeData.duty" name="Görevi" label="Görevi:" placeholder="Görev Seçiniz..." rules="required" />
      </div>
      <app-date-input v-model="employeeData.dateOfStartWorking" name="İşe Başlama Tarihi" label="İşe Başlama Tarihi:" placeholder="Tarih Seçiniz..." rules="required" />
      <div v-if="auth.role && auth.role.group === 'physician'">
        <app-input v-model="data.sertificateNumber" type="text" name="Sertifika Numarası" label="Sertifika Numarası:" placeholder="Sertifika Numarası" rules="required" />
        <app-select-input
          v-model="data.workType"
          name="Çalışma Şekli"
          label="Çalışma Şekli:"
          placeholder="Çalışma Şekli"
          :options="['Tam Zamanlı', 'Yarı Zamanlı']"
          rules="required"
          @input="setAssignmentTime"
        />
        <app-input
          v-show="data.workType === 'Yarı Zamanlı'"
          v-model.number="data.assignmentTime"
          type="number"
          name="Atama Süresi"
          placeholder="Atama Süresi"
          rules="required"
          right-text-icon="Dk."
        />
      </div>
      <b-form-checkbox v-model="usingPanel" class="custom-control-primary" :disabled="Boolean(updateItem)" @change="checkRole"> Panel Kullanımı </b-form-checkbox>
      <hr />
      <app-button :text="updateItem ? 'Güncelle' : 'Oluştur'" block @click="updateItem ? updateUser() : newUser()" />
      <hr />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import AuthForm from "@/views/Users/AuthForm.vue";

export default {
  components: {
    ValidationObserver,
    AuthForm,
  },
  data() {
    return {
      usingPanel: true,
      auth: {},
      data: {},
      employeeData: {},
      roles: [],
      roleGroups: [],
      riskomerBranches: [],
    };
  },
  computed: {
    updateItem() {
      return this.$store.getters?.appModalSidebarItem;
    },
    sidebarItem() {
      return this.$store.getters?.appSidebarItem;
    },
    appModalItemCallback() {
      return this.$store.getters?.appModalItem?.callback;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.updateItem) {
        this.auth = { ...this.updateItem?.auth };
        this?.auth?.role?.group === "physician" && ["sertificateNumber", "workType", "assignmentTime"].map((key) => (this.data[key] = this.updateItem?.physician[key]));
        this.employeeData.dateOfStartWorking = this.updateItem?.dateOfStartWorking;
        this.employeeData.branch = this.updateItem?.branch?._id;
      }
      this.getRoles();
      this.getRiskomerBranches();
    });
  },
  methods: {
    setData() {
      return {
        auth: {
          ...this.auth,
          role: this.auth.role?._id,
          phone: this.auth.phone.replaceAll(" ", ""),
          usingPanel: this.usingPanel,
        },
        data: this.data,
        employeeData: {
          ...this.employeeData,
          dateOfStartWorking: new Date(this.employeeData.dateOfStartWorking),
        },
      };
    },
    newUser() {
      this.$validate(this.$refs.employeeform, () => {
        this.$axios
          .post("/riskomer-employees", this.setData(), { loading: "sidebar" })
          .then((res) => {
            this.$emitter.$emit("Notification", {
              variant: "success",
            });
            this.sidebarItem?.callback && this.sidebarItem?.callback();
            this.$store.commit("cleanAppSidebar");
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    updateUser() {
      this.$validate(this.$refs.employeeform, () => {
        this.$axios
          .patch(`/riskomer-employees/update/${this.updateItem?._id}`, this.setData(), { loading: "sidebar" })
          .then((res) => {
            this.$emitter.$emit("Notification", {
              variant: "success",
            });
            this.appModalItemCallback && this.appModalItemCallback();
            this.$store.commit("setAppModalOptions", {
              title: `${res?.data?.auth?.fullname} - ${res?.data?.auth?.role?.value ?? res?.data?.duty}`,
              item: { data: { ...res?.data, physician: this.updateItem?.physician }, callback: this.appModalItemCallback },
            });
            this.$store.commit("cleanAppModalSidebar");
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", {
              variant: "danger",
            });
          });
      });
    },
    checkRole() {
      this.auth?.role ? (this.auth.role = null) : (this.employeeData.duty = null);
    },
    getRoles() {
      this.$axios
        .get("/permissions/roles", { params: { group: "physician" } })
        .then((res) => {
          this.roles = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRiskomerBranches() {
      this.$axios
        .get("/riskomer-branches")
        .then((res) => {
          this.riskomerBranches = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setAssignmentTime() {
      this.data.workType === "Tam Zamanlı" ? (this.data.assignmentTime = 11700) : (this.data.assignmentTime = 0);
    },
  },
};
</script>
