<template>
  <app-overlay loading-variable="sidebarOverlay">
    <ValidationObserver ref="assignmentForm">
      <uncompleted-company-list v-model="company" />
      <uncompleted-branch-list v-model="branch" :company="company" :role="role" />
      <hr />
      <app-button text="Atama Yap" block @click="makeAssignment" />
      <hr />
    </ValidationObserver>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import UncompletedCompanyList from "./UncompletedCompanyList.vue";
import UncompletedBranchList from "./UncompletedBranchList.vue";

export default {
  components: { ValidationObserver, UncompletedCompanyList, UncompletedBranchList },
  data() {
    return {
      company: null,
      branch: null,
    };
  },
  computed: {
    physician() {
      return this.$store.getters.appModalItem?.data?.physician?._id;
    },
    role() {
      return this.$store.getters.appModalItem?.data?.auth?.role?.key;
    },
    callback() {
      return this.$store.getters.appModalSidebarItem?.callback;
    },
  },
  methods: {
    makeAssignment() {
      this.$validate(this.$refs.assignmentForm, () => {
        this.$store.dispatch("newAssignment", { data: this.setData(), callback: this.callback });
      });
    },
    setData() {
      return {
        company: this.company,
        branch: this.branch,
        physician: this.physician,
      };
    },
  },
};
</script>

<style></style>
