<template>
  <app-overlay loading-variable="modalOverlay">
    <db-searchable-table v-model="filters" @changed="getData" :items="assignments" :fields="fields" :total-rows="total" searchable>
      <template #modalbutton>
        <app-button class="flex-1-md" text="Atama Yap" size="md" icon="PlusIcon" @click="$showAppModalSidebar('Atama Yap', AssignmentForm, { callback: getData })" />
      </template>
      <template slot="company.name" slot-scope="data">
        <div v-if="data.item.company && data.item.company.name">
          <span v-b-tooltip.hover.bottom="data.item.company.name.length > 20 ? data.item.company.name : ''">
            {{ $fn(data.item.company.name, 20) }}
          </span>
        </div>
      </template>
      <template #isAssignmentActive="{ item }">
        <b-badge :variant="item.isAssignmentActive ? 'success' : 'danger'">
          {{ item.isAssignmentActive ? "Aktif" : "Pasif" }}
        </b-badge>
      </template>
      <template #physicianConfirm="{ item }">
        <b-badge :variant="$variants[item.physicianConfirm]">
          {{ $capitalize(item.physicianConfirm) }}
        </b-badge>
      </template>
      <template #companyConfirm="{ item }">
        <b-badge :variant="$variants[item.companyConfirm]">
          {{ $capitalize(item.companyConfirm) }}
        </b-badge>
      </template>
      <template #actions="{ item }">
        <app-icon-button v-if="item.isAssignmentActive" v-b-tooltip.hover.bottom="'Atamayı Kaldır'" variant="gradient-danger" icon="XIcon" @click="deactiveAssignment(item)" />
        <span v-else> - </span>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import AssignmentForm from "./AssignmentForm.vue";

export default {
  data() {
    return {
      filters: {},
      fields: [
        { key: "company.name", label: "Firma" },
        { key: "branch.name", label: "Sicil" },
        { key: "physicianConfirm", label: "Görevli Onayı" },
        { key: "companyConfirm", label: "Firma Onayı" },
        { key: "isAssignmentActive", label: "Atama Durumu" },
        {
          key: "createdAt",
          label: "Atama Tarihi",
          formatDateTime: true,
        },
        {
          key: "passivizationDate",
          label: "Kaldırılma Tarihi",
          formatDateTime: true,
        },
        { key: "actions", label: "İşlemler", sortable: false },
      ],
      AssignmentForm,
    };
  },
  computed: {
    item() {
      return this.$store.getters?.appModalItem?.data;
    },
    assignments() {
      return this.$store.getters?.assignments?.data ?? [];
    },
    total() {
      return this.$store.getters?.assignments?.total ?? 0;
    },
  },
  methods: {
    getData() {
      this.$axios
        .get("/rest-assignments/paginated-assignments", {
          params: {
            ...this.filters,
            sortBy: this.filters?.sortBy ?? "isAssignmentActive",
            physician: this.item?.physician?._id,
            populates: JSON.stringify(["company", "branch"]),
          },
          loading: "modal",
        })
        .then((result) => {
          this.$store.commit("setAssignments", result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deactiveAssignment(item) {
      this.$confirm({ message: "Atamayı kaldırmak istediğinize emin misiniz?" }, () => {
        this.$store.dispatch("deactiveAssignment", { id: item?._id, callback: this.getData });
      });
    },
  },
  mounted() {
    if (this.item) {
      this.getData();
    }
  },
  destroyed() {
    this.$store.commit("setAssignments", { data: [], total: 0 });
  },
};
</script>

<style></style>
