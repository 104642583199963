<template>
  <app-overlay>
    <db-searchable-table v-model="filters" @changed="getData" :items="items" :total-rows="total" :fields="fields" hover filterable @row-clicked="showDetails" searchable>
      <template #modalbutton>
        <app-button text="Çalışan Ekle" icon="PlusIcon" size="md" @click="showNewForm" />
      </template>
      <template #duty="{ item }">
        <span v-if="item.duty">
          {{ item.duty }}
        </span>
        <span v-else-if="item.auth.role">
          {{ item.auth.role.value }}
        </span>
        <span v-else> - </span>
      </template>
      <template #actions="{ item }">
        <div class="d-flex gap-5 align-items-center">
          <b-form-checkbox
            v-if="item.auth.usingPanel"
            v-model="item.auth.allowed"
            v-b-tooltip.hover.bottom="'Panel Kullanımı'"
            class="custom-control-success"
            name="check-button"
            switch
            @change="changeStatus(item.auth, $event)"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </div>
      </template>
      <template #filtercollapse>
        <b-row>
          <b-col md="4">
            <app-select-input v-model="selectedRole" name="Görev" label="Görev:" :options="roles" placeholder="Seçim Yapınız..." select_label="value" no-gap @input="roleFilter" />
          </b-col>
        </b-row>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import physicianForm from "./physicianForm.vue";
import employeeDetails from "./details/employeeDetails.vue";

export default {
  data() {
    return {
      selectedRole: null,
      filters: {},
      items: [],
      total: 0,
      generalFields: [
        { key: "auth.fullname", label: "Adı Soyadı" },
        { key: "duty", label: "Görevi" },
        { key: "branch.name", label: "Şube" },
        {
          key: "dateOfStartWorking",
          label: "İşe Başlama Tarihi",
          formatDate: true,
        },
        {
          key: "dateOfLeaving",
          label: "İşten Çıkış Tarihi",
          formatDate: true,
        },
        {
          key: "lastLogin.createdAt",
          label: "Son Giriş",
          formatDateTime: true,
        },
        { key: "actions", label: "İşlemler" },
      ],
      physicianFields: [
        { key: "auth.fullname", label: "Adı Soyadı" },
        { key: "duty", label: "Görevi" },
        { key: "branch.name", label: "Şube" },
        {
          key: "dateOfStartWorking",
          label: "İşe Başlama Tarihi",
          formatDate: true,
        },
        { key: "physician.assignedBranches", label: "Sicil Sayısı" },
        {
          key: "physician.assignmentTime",
          label: "İçe Atama",
          addition: " Dk.",
        },
        {
          key: "physician.assignedTime",
          label: "Dışa Atama",
          addition: " Dk.",
        },
        {
          key: "physician.totalTimeLeft",
          label: "Kalan Süre",
          addition: " Dk.",
        },
        { key: "actions", label: "İşlemler" },
      ],
      fields: [],
      physicianForm,
      roles: [],
    };
  },
  mounted() {
    this.getRoles();
    this.fields = this.generalFields;
  },
  methods: {
    showDetails(item) {
      this.$axios
        .get("/riskomer-employees/get-one", { params: { _id: item?.riskomeremployee?._id ?? item?._id }, loading: "table" })
        .then((result) => {
          this.$showAppModal({
            title: `${result?.data?.auth?.fullname} - ${result?.data?.auth?.role?.value ?? result?.data?.duty}`,
            component: employeeDetails,
            item: { data: result?.data, callback: this.getData },
            size: "custom",
            centered: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showNewForm() {
      this.$showAppSidebar("Çalışan Ekle", physicianForm, { callback: this.getData });
    },
    getData() {
      this.$axios
        .get("/riskomer-employees/list/paginated", {
          params: { ...this.filters, "auth.role.key": this.selectedRole?.key, searchFields: JSON.stringify(["auth.fullname"]) },
          loading: "table",
        })
        .then((result) => {
          this.items = result?.data?.data;
          this.total = result?.data?.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRoles() {
      this.$axios
        .get("/permissions/roles", { params: { group: "physician" } })
        .then((res) => {
          this.roles = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeStatus(val, allowed) {
      this.$axios
        .patch(`/change-user-status/${val._id}`, { allowed })
        .then((res) => {
          this.$emitter.$emit("Notification", {
            variant: "success",
            message: "Kullanıcı durumu başarıyla değiştirildi.",
            title: "İşlem Başarılı",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$emitter.$emit("Notification", {
            variant: "danger",
            message: "Kullanıcı durumu değiştirilemedi.",
            title: "İşlem Başarısız",
          });
        });
    },
    roleFilter(role) {
      this.fields = role ? this.physicianFields : this.generalFields;
      this.getData();
      /* if (role) {
        if (role.group === "physician") {
          this.$store.dispatch("getRiskomerPhysicians", role.key);
          this.fields = this.physicianFields;
        }
      } else {
        this.fields = this.generalFields;
        this.$store.dispatch("getAllRiskomerEmployees");
      } */
    },
  },
};
</script>

<style></style>
