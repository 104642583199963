<template>
  <app-overlay loading-variable="modalOverlay">
    <b-row v-if="user">
      <b-col md="6">
        <b-card body-class="p-1" class="border rounded">
          <app-detail-table :fields="profileFields" :data="user">
            <template #duty="item">
              <span v-if="item.duty">
                {{ item.duty }}
              </span>
              <span v-else-if="item.auth.role">
                {{ item.auth.role.value }}
              </span>
              <span v-else> - </span>
            </template>
          </app-detail-table>
          <hr class="mt-0" />
          <b-row style="gap: 5px 0">
            <b-col v-if="user.auth.usingPanel && user.auth.allowed" lg="4">
              <app-button text="Şifre Yenile" icon="RefreshCwIcon" block @click="refreshPassword" />
            </b-col>
            <b-col lg="4">
              <app-button text="Düzenle" icon="EditIcon" block @click="editPhysician" />
            </b-col>
            <b-col v-if="!user.dateOfLeaving" lg="4">
              <app-button @click="showLeavingForm" text="İşten Çıkış" icon="XIcon" block />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col v-if="!user.dateOfLeaving" md="6">
        <b-card v-if="user.auth.role && user.auth.role.group === 'physician'" class="border">
          <physician-assignment-info :user-id="user.auth._id" />
        </b-card>
      </b-col>
    </b-row>
  </app-overlay>
</template>

<script>
import physicianForm from "../physicianForm.vue";
import physicianAssignmentInfo from "./physicianAssignmentInfo.vue";
import LeavingForm from "./LeavingForm.vue";

export default {
  components: { physicianAssignmentInfo },
  data() {
    return {
      profileFields: [
        { key: "auth.fullname", label: "İsim", icon: "UserIcon" },
        { key: "auth.phone", label: "Telefon", icon: "PhoneCallIcon" },
        { key: "auth.email", label: "E-Posta", icon: "MailIcon" },
        { key: "duty", label: "Görevi", icon: "FileIcon" },
        {
          key: "dateOfStartWorking",
          label: "İşe Başlama Tarihi",
          icon: "CalendarIcon",
          formatDate: true,
        },
        {
          key: "dateOfLeaving",
          label: "İşten Ayrılma Tarihi",
          icon: "CalendarIcon",
          formatDate: true,
        },
        {
          key: "createdAt",
          label: "Kayıt Tarihi",
          icon: "CalendarIcon",
          formatDateTime: true,
        },
        {
          key: "auth.lastLogin.createdAt",
          label: "Son Başarılı Giriş",
          icon: "CalendarIcon",
          formatDateTime: true,
        },
        {
          key: "auth.dateOfFirstClickSms",
          label: "Sms Tıklanma",
          icon: "CalendarIcon",
          formatDateTime: true,
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters?.appModalItem?.data;
    },
  },
  methods: {
    refreshPassword() {
      this.$axios
        .patch(`/riskomer-employees/refresh-password/${this?.user?.auth?._id}`, {}, { loading: "modal" })
        .then((res) => {
          if (res) this.$emitter.$emit("Notification", { title: "Şifre Yenilendi", variant: "success" });
        })
        .catch((err) => {
          console.log(err);
          this.$emitter.$emit("Notification", { title: "Şifre Yenilenemedi", variant: "danger" });
        });
    },
    editPhysician() {
      this.$showAppModalSidebar("Düzenle", physicianForm, { ...this.user });
    },
    showLeavingForm() {
      this.$showAppModalSidebar("İşten Çıkış", LeavingForm);
    },
  },
};
</script>

<style></style>
