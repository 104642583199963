<template>
  <b-custom-tabs :tabs="tabs" lazy />
</template>

<script>
import info from "@/views/Riskomer/Employees/details/info.vue";
import employeeFileList from "./employeeFiles/employeeFileList.vue";
import AssignmentList from "./assignments/AssignmentList.vue";
import UserLogs from "./UserLogs.vue";

export default {
  data() {
    return {
      /*       tabs: [
        { title: "Genel", component: info },
        { title: "Kariyer" },
        { title: "İzinler" },
        { title: "Zimmetler" },
        { title: "Eğitimler" },
        { title: "Dosyalar", component: employeeFileList },
        { title: "Atamalar", component: AssignmentList },
        { title: "Notlar" },
      ], */
    };
  },
  computed: {
    item() {
      return this.$store.getters?.appModalItem?.data;
    },
    tabs() {
      return [
        { title: "Genel", component: info },
        { title: "Kariyer" },
        { title: "İzinler" },
        { title: "Zimmetler" },
        { title: "Eğitimler" },
        { title: "Dosyalar", component: employeeFileList },
        ...(this.item?.auth?.role?.group === "physician" ? [{ title: "Atamalar", component: AssignmentList }] : []),
        { title: "Girişler", component: UserLogs },
        { title: "Notlar" },
      ];
    },
  },
  mounted() {
    if (this.item?.auth?.role?.group !== "physician") {
      this.tabs.splice(6, 1);
    }
  },
};
</script>

<style></style>
