<template>
  <app-select-input
    v-model="branch"
    :reduce="(option) => option._id"
    name="Sicil"
    :options="branches"
    label="Sicil:"
    select_label="name"
    placeholder="Sicil Seçiniz..."
    rules="required"
    :loading="loading"
    @input="$emit('input', branch)"
  />
</template>

<script>
export default {
  props: {
    company: {
      type: String,
      default: null,
    },
    role: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      branches: [],
      branch: null,
      loading: false,
    };
  },
  watch: {
    company() {
      this.getBranches();
    },
  },

  mounted() {
    this.getBranches();
  },
  methods: {
    getBranches() {
      this.branch = null;
      this.$emit("input", null);
      if (this.company) {
        this.loading = true;
        this.$axios
          .get("/company/uncompleted-branch-assignments", { params: { role: this.role, company: this.company } })
          .then((res) => {
            this.branches = res.data;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style></style>
