<template>
  <app-overlay loading-variable="modalOverlay">
    <db-searchable-table v-model="filters" @changed="getFiles" class="border" :items="fileList" :total-rows="total" :fields="fields" searchable>
      <template #modalbutton>
        <app-button text="Dosya Yükle" icon="UploadIcon" size="md" @click="showForm" />
      </template>
      <template #actions="{ item }">
        <app-dropdown>
          <b-dropdown-item @click="downloadFile(item._id)"> <feather-icon icon="DownloadIcon" class="mr-50" /> İndir</b-dropdown-item>
          <b-dropdown-item @click="deleteFile(item._id)"> <feather-icon icon="TrashIcon" class="mr-50" /> Sil</b-dropdown-item>
        </app-dropdown>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import employeeFileForm from "./employeeFileForm.vue";

export default {
  data() {
    return {
      filters: {},
      fields: [
        { key: "customName", label: "Dosya Adı" },
        { key: "fileGroup.value", label: "Dosya Türü" },
        { key: "createdAt", label: "Yüklenme Tarihi", formatDateTime: true },
        { key: "actions", label: "Eylemler" },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters?.appModalItem?.data;
    },
    fileList() {
      return this.$store.getters?.employeeFiles?.data ?? [];
    },
    total() {
      return this.$store.getters?.employeeFiles?.total ?? 0;
    },
  },
  mounted() {
    this.getFiles();
  },
  methods: {
    showForm() {
      this.$showAppModalSidebar("Dosya Yükle", employeeFileForm, { callback: this.getFiles });
    },
    getFiles() {
      this.$axios
        .get("/riskomer-employee-files", { params: { ...this.filters, riskomerEmployee: this.user._id }, loading: "modal" })
        .then((res) => {
          this.$store.commit("setEmployeeFiles", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    downloadFile(id) {
      this.$axios
        .get(`/riskomer-employee-files/${id}`, { loading: "modal", responseType: "blob" })
        .then((res) => {
          const blob = new Blob([res.data], { type: res.headers["content-type"] });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank");
        })
        .catch((err) => {
          this.$emitter.$emit("Notification", {
            variant: "danger",
            title: "Hata Oluştu",
            message: err?.data?.message ?? null,
          });
        });
    },
    deleteFile(id) {
      this.$confirm({ message: "Dosya silinecek emin misiniz?" }, () => {
        this.$axios
          .delete(`/riskomer-employee-files/${id}`, { loading: "modal" })
          .then((res) => {
            this.getFiles();
            this.$emitter.$emit("Notification", {
              variant: "success",
              title: "İşlem Başarılı",
              message: "Dosya sistemden başarıyla silindi.",
            });
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", {
              variant: "danger",
              title: "İşlem Başarısız",
              message: "Dosya sistemden silinirken bir hata oluştu.",
            });
          });
      });
    },
  },
};
</script>

<style></style>
