<template>
  <app-overlay loading-variable="sidebarOverlay">
    <small>
      - Sadece PDF, JPG ve PNG dosya formatı yüklenebilir. <br />
      - En fazla 5 MB boyutunda dosya yükleyebilirsiniz.
    </small>
    <hr />
    <validation-observer ref="fileinput">
      <app-input v-model="customName" name="Dosya Adı" label="Dosya Adı:" placeholder="Dosya Adı..." rules="required" />
      <app-select-input name="Dosya Türü" v-model="fileGroup" :options="fileGroups" label="Dosya Türü" select_label="value" placeholder="Tür Seçiniz..." rules="required" />
      <app-file-input v-model="file" label="Dosya:" :accept="$acceptMimes(['jpg', 'jpeg', 'png', 'pdf'])" requires />
    </validation-observer>
    <hr />
    <app-button icon="UploadIcon" icon-position="left" size="md" text="Yükle" block @click="signCheck" />
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";

export default {
  components: { ValidationObserver },
  data() {
    return {
      file: null,
      customName: null,
      fileGroup: null,
      fileGroups: [
        { key: "sign", value: "Kaşe - İmza" },
        { key: "personnel", value: "Özlük Dosyası" },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters?.appModalItem?.data;
    },
    callback() {
      return this.$store.getters?.appModalSidebarItem?.callback;
    },
  },
  methods: {
    setData() {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("fileGroup", JSON.stringify(this.fileGroup));
      formData.append("customName", this.customName);
      formData.append("riskomerEmployee", this.user._id);
      return formData;
    },
    signCheck() {
      this.$validate(this.$refs.fileinput, () => {
        if (this.fileGroup.key === "sign") {
          this.$axios
            .get(`/riskomer-employee-files/sign-check/${this.user?._id}`, { loading: "sidebar" })
            .then((res) => {
              res.status === 201
                ? this.$confirm({ message: "Sistemde yüklü kaşe - imza mevcut. Eski kayıt silinip yenisi eklenecektir onaylıyor musunuz? " }, () => {
                    this.uploadFile();
                  })
                : this.uploadFile();
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.uploadFile();
        }
      });
    },
    uploadFile() {
      this.$validate(this.$refs.fileinput, () => {
        this.$axios
          .post("/riskomer-employee-files", this.setData(), { loading: "sidebar" })
          .then((res) => {
            this.callback ? this.callback() : this.$store.commit("newRiskomerEmployeeFile", res.data);
            this.$emitter.$emit("Notification", { title: "İşlem Başarılı", message: "Dosya başarıyla yüklendi.", variant: "success" });
            this.$store.commit("cleanAppModalSidebar");
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
};
</script>

<style>
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Dosya Seç";
}
</style>
