<template>
  <app-select-input
    v-model="company"
    :reduce="(option) => option._id"
    name="Firma"
    :options="companies"
    label="Firma:"
    select_label="name"
    placeholder="Firma Seçiniz..."
    rules="required"
    @input="$emit('input', company)"
  />
</template>

<script>
export default {
  data() {
    return {
      companies: [],
      company: null,
      loading: false,
    };
  },
  computed: {
    role() {
      return this.$store.getters.appModalItem?.data?.auth?.role?.key;
    },
  },
  mounted() {
    this.getCompanies();
  },
  methods: {
    getCompanies() {
      this.loading = true;
      this.$axios
        .get("/company/uncompleted-assignments", { params: { role: this.role } })
        .then((res) => {
          this.companies = res.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
