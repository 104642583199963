<template>
  <b-custom-tabs id="riskomer-tabs" :tab-index="tabIndex" :tabs="tabs" lazy> </b-custom-tabs>
</template>

<script>
import employeeList from "./Employees/employeeList.vue";
import Administrators from "../Users/Administrators/Administrators.vue";
export default {
  props: ["tabIndex"],
  data() {
    return {
      tabs: [
        { title: "Çalışanlar", component: employeeList, route: "riskomer-employees" },
        { title: "İdari Personel", component: Administrators, route: "riskomer-admins" },
        { title: "Eğitimler" },
        { title: "Performans" },
        { title: "İzinler" },
        { title: "Zimmetler" },
        { title: "Fazla Mesai" },
      ],
    };
  },
};
</script>

<style></style>
