var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-overlay',{attrs:{"loading-variable":"modalOverlay"}},[_c('db-searchable-table',{attrs:{"items":_vm.assignments,"fields":_vm.fields,"total-rows":_vm.total,"searchable":""},on:{"changed":_vm.getData},scopedSlots:_vm._u([{key:"modalbutton",fn:function(){return [_c('app-button',{staticClass:"flex-1-md",attrs:{"text":"Atama Yap","size":"md","icon":"PlusIcon"},on:{"click":function($event){return _vm.$showAppModalSidebar('Atama Yap', _vm.AssignmentForm, { callback: _vm.getData })}}})]},proxy:true},{key:"company.name",fn:function(data){return [(data.item.company && data.item.company.name)?_c('div',[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:(data.item.company.name.length > 20 ? data.item.company.name : ''),expression:"data.item.company.name.length > 20 ? data.item.company.name : ''",modifiers:{"hover":true,"bottom":true}}]},[_vm._v(" "+_vm._s(_vm.$fn(data.item.company.name, 20))+" ")])]):_vm._e()]}},{key:"isAssignmentActive",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":item.isAssignmentActive ? 'success' : 'danger'}},[_vm._v(" "+_vm._s(item.isAssignmentActive ? "Aktif" : "Pasif")+" ")])]}},{key:"physicianConfirm",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":_vm.$variants[item.physicianConfirm]}},[_vm._v(" "+_vm._s(_vm.$capitalize(item.physicianConfirm))+" ")])]}},{key:"companyConfirm",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":_vm.$variants[item.companyConfirm]}},[_vm._v(" "+_vm._s(_vm.$capitalize(item.companyConfirm))+" ")])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [(item.isAssignmentActive)?_c('app-icon-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Atamayı Kaldır'),expression:"'Atamayı Kaldır'",modifiers:{"hover":true,"bottom":true}}],attrs:{"variant":"gradient-danger","icon":"XIcon"},on:{"click":function($event){return _vm.deactiveAssignment(item)}}}):_c('span',[_vm._v(" - ")])]}}]),model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }