<template>
  <app-overlay loading-variable="sidebarOverlay">
    <ValidationObserver ref="leavingForm">
      <app-date-input v-model="leaveDate" name="İşten Ayrılış Tarihi" label="İşten Ayrılış Tarihi:" placeholder="Tarih seçiniz..." rules="required" />
      <app-button v-model="leaveDate" @click="updateEmployee" text="Güncelle" block />
    </ValidationObserver>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";

export default {
  components: {
    ValidationObserver,
  },
  data() {
    return {
      leaveDate: null,
    };
  },
  methods: {
    updateEmployee() {
      this.$validate(this.$refs.leavingForm, () => {
        this.$confirm({ message: "Çalışanın tüm panel kullanımı kapatılacak ve atamaları devre dışı bırakılacaktır emin misiniz?" }, () => {
          this.$axios
            .patch(`/riskomer-employees/update-leaved/${this.user?._id}`, { dateOfLeaving: this.leaveDate }, { loading: "sidebar" })
            .then((res) => {
              this.callback && this.callback();
              this.$emitter.$emit("Notification", {
                variant: "success",
              });
              this.$store.commit("setAppModalOptions", {
                item: { data: { ...res?.data, physician: this.updateItem?.physician }, callback: this.appModalItemCallback },
              });
              this.$store.commit("cleanAppModalSidebar");
            })
            .catch((err) => {
              console.log(err);
              this.$emitter.$emit("Notification", {
                variant: "danger",
              });
            });
        });
      });
    },
  },
  computed: {
    user() {
      return this.$store?.getters?.appModalItem?.data;
    },
    callback() {
      return this.$store?.getters?.appModalItem?.callback;
    },
  },
};
</script>

<style lang="postcss" scoped></style>
